import Text from "@atoms/Text";
import { WhyInvestData } from "@helpers/homepageData";
import texts from '@components/Homepage/en.json';
import Image from "@atoms/Image";

const WhyInvest = ({
    titleTag = 'h2'
}) => {
    return (
        <div className="flex flex-col items-center px-4 py-12 max-width-wrapper md:py-16 xl:pt-18 xl:pb-18 md:px-6 xl:px-16 ">
            <Text
                content={texts?.WhyInvest}
                className="text-primary-500 h4-semibold md:h3-semibold xl:h2-semibold"
                htmlTag={titleTag}
            />
            <Text
                content={texts?.EffortlessInvestment}
                className="text-center text-gray-600 p3-regular md:p2-regular"
            />
            <div
                className="w-full mt-8 md:mt-10 xl:mt-16  gap-x-6 md:gap-x-4 max-w-[480px] md:max-w-[560px] gap-y-10 justify-center xl:gap-x-[43px] grid items-center place-items-center grid-cols-2 xl:grid-cols-4 xl:max-w-[928px]">
                {WhyInvestData?.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-col justify-start gap-4 items-center max-w-[152px] md:max-w-[226px] xl:max-w-[196px] mx-auto md:mx-0 w-full`}
                    >
                        <Image
                            src={item.image}
                            className="h-14 md:h-16 xl:h-18"
                            loading='lazy'
                            alt={item.alt}
                        />
                        <Text
                            content={item.text}
                            className="text-center text-basicBlack p4-regular "
                            isInnerHtml={true}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhyInvest;